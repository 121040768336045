import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";

// Assets

const CareersFirmwareEngineer = () => (
  <Layout currentPage="careers-listing">
    <div className="container">
      <Link to="/careers#alljobs">
        <p className="backbutton col-md-12">Back to Careers</p>
      </Link>
      <h1 className="jobtitle col-md-12">Chief Sustainability Officer</h1>
      <div className="jobdescription col-md-12">
        Make an impact by showing the world that what’s good for the planet is
        good for business. Help us reach, educate, inspire people and companies to make the right choices. 
      </div>
      <div className="row col-md-12 justify-content-md-between">
        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You will:
          </div>
        </div>
        <div className="col-md-9">
          <ul className="aligned-left jobsection">
            <li>
              Guide our product and operations teams in defining and
              implementing Uno products for maximum impact, including affecting
              the experience that reflects the sustainable nature of our
              offering.
            </li>
            <li>
              Educate sales teams and be involved in sales process as a credible
              knowledge source.
            </li>
            <li>
              Interface with partners’ Chief Sustainability Officers on
              sustainability assessment and accreditation programs and creating
              momentum for Uno within their organizations.
            </li>
            <li>
              Work with our leadership, department heads and vendors to
              establish sustainable practices in sourcing, manufacturing,
              distribution, etc.
            </li>
            <li>
              Collaborate with marketing team to develop messaging and
              collateral that is accurate, compelling and substantiated.
            </li>
            <li>
              Identify opportunities such as partnerships, certifications,
              policy and regulation that can bring value to Uno, its users and
              the planet.
            </li>
            <li>Represent Uno in conferences, panels and press.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">You are:</div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>Wholeheartedly devoted to creating a sustainable planet.</li>
            <li>Highly collaborative and able to bring people together.</li>
            <li>An effective communicator who can move people.</li>
            <li>Infectious in your passion for doing the right thing.</li>
            <li>Actively seeking solutions to big problems.</li>
            <li>Patient with people, organizations and processes.</li>
            <li>Reliable at providing accurate and detailed information.</li>
            <li>Able to explain complex topics in thought-provoking ways.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You bring:
          </div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>
              BA in Business, Sustainability Management, or related fields.{" "}
            </li>
            <li>5+ years of experience in corporate sustainability.</li>
            <li>
              Deep understanding of sustainability concepts such as the
              circular economy, power purchase agreements, recycling behavior
              design, biophilia and life cycle analysis.
            </li>
            <li>
              Understanding of sustainability certifications and best practices
              such as LEED, TRUE, SITE, etc.
            </li>
            <li>
              Knowledge of sustainability metrics and assessment methodologies.
            </li>
            <li>
              A vast network of connections in the corporate, public and academic
              sustainability world.
            </li>
            <li>
              Direct experience with or demonstrated knowledge of corporate and
              public sector sustainability programs.
            </li>
            <li>
              Experience managing multiple projects simultaneously involving
              diverse stakeholders and requiring frequent communication.
            </li>
            <li>Working at startup or tech company is a plus.</li>
            <li>Global experience is a plus.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You apply:
          </div>
        </div>
        <div className="col-md-9">
          <div className="aligned-left" style={{ paddingBottom: "100px" }}>
            By sending us an <a href="mailto:careers@introducing.uno">email</a>{" "}
            with your resume and a cover letter.
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CareersFirmwareEngineer;
